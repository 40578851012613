import useSoundLibrary from "use-sound";
import { soundSetting as serviceSoundSetting } from "service";
import {
  GameOver,
  SoundClickButton,
  SoundDuplicate,
  SoundGreenLine,
  SoundOrangeTile,
  SoundOrangeTileDelay,
  CricketSoundSuspense777,
  CricketStrikeSound,
  CricketSoundWin,
  CountUpWin,
  JackpotSound,
  SwooshSound,
  TripleSevenLastHit,
  SideBetWinner,
  CricketBatImpact,
  CricketSportRunningSound,
  CricketCrowdCheer,
  BaseballSoundGreenLine,
  BaseballCannon,
  BaseballOrgan1,
  BaseballOrgan2,
  BaseballOrgan3,
  BaseballSportsCrowd,
  BaseballAnthem,
  BaseballCrowdBoo,
  BaseballOut,
  BaseballRollingSuspense,
} from "assets/audio";
import { createEmptySoundStub } from "service/sound";

export const useSound = (soundEnabled) => {
  const soundSetting = {
    ...serviceSoundSetting,
    soundEnabled
  };
  // const
  const [playSuspense777, { stop: stopSuspense777 }] = useSoundLibrary(CricketSoundSuspense777, { ...soundSetting, loop: true });
  const [playSoundOrangeTile] = useSoundLibrary(SoundOrangeTile, soundSetting);
  const [playSoundClickButton] = useSoundLibrary(SoundClickButton, soundSetting);
  const [playSoundOrangeTileDelay] = useSoundLibrary(
    SoundOrangeTileDelay,
    soundSetting
  );
  const playSoundOrangeClick = createEmptySoundStub();
  const playSoundRolling = createEmptySoundStub();
  const stopSoundRolling = createEmptySoundStub();
  const [playSoundDuplicate] = useSoundLibrary(SoundDuplicate, soundSetting);
  const [playSideBetWinner] = useSoundLibrary(SideBetWinner, soundSetting);
  const [playSoundGreenLine] = useSoundLibrary(SoundGreenLine, soundSetting);
  const [playBaseballSoundGreenLine] = useSoundLibrary(BaseballSoundGreenLine, soundSetting);
  const [playSoundStrike] = useSoundLibrary(CricketStrikeSound, soundSetting);
  const [playSoundBoo] = useSoundLibrary(BaseballCrowdBoo, soundSetting);
  const [playSoundWin] = useSoundLibrary(CricketSoundWin, soundSetting);

  const [playCountUpWin] = useSoundLibrary(CountUpWin, soundSetting);
  const [playGameOver] = useSoundLibrary(GameOver, soundSetting);
  const [playJackpotSound] = useSoundLibrary(JackpotSound, soundSetting);
  const [playSwooshSound] = useSoundLibrary(SwooshSound);
  const [playWinSound] = useSoundLibrary(TripleSevenLastHit, soundSetting);
  const [playBallThrow] = useSoundLibrary(BaseballCannon, soundSetting);
  const [playBatImpact] = useSoundLibrary(CricketBatImpact, soundSetting);
  const [playCrowdCheer, { stop: stopCrowdCheer }] = useSoundLibrary(CricketCrowdCheer, soundSetting);
  const playBallsFillingHopper = createEmptySoundStub();
  const stopPlayBallsFillingHopper = createEmptySoundStub();
  const [playSportRunningSound] = useSoundLibrary(CricketSportRunningSound, soundSetting);
  const [playOut] = useSoundLibrary(BaseballOut, soundSetting);
  const [playOrgan1, { stop: stopOrgan1 }] = useSoundLibrary(BaseballOrgan1, {...soundSetting, loop: true});
  const [playOrgan2, { stop: stopOrgan2 }] = useSoundLibrary(BaseballOrgan2, {...soundSetting, loop: true});
  const [playOrgan3, { stop: stopOrgan3 }] = useSoundLibrary(BaseballOrgan3, {...soundSetting, loop: true});
  const [playSportsCrowd, { stop: stopSportsCrowd }] = useSoundLibrary(BaseballSportsCrowd, {...soundSetting, loop: true});
  const [playAnthem, {stop: stopAnthem}] = useSoundLibrary(BaseballAnthem, {...soundSetting, loop: true});
  const [playRollingBallSuspense, {stop: stopRollingBallSuspense}] = useSoundLibrary(BaseballRollingSuspense, {...soundSetting, loop: true});

  return {
    playRollingBallSuspense,
    stopRollingBallSuspense,
    playSuspense777,
    stopSuspense777,
    playSoundOrangeTile,
    playSoundClickButton,
    playSoundOrangeTileDelay,
    playSoundOrangeClick,
    playSoundRolling,
    stopSoundRolling,
    playSoundDuplicate,
    playSideBetWinner,
    playSoundGreenLine,
    playBaseballSoundGreenLine,
    playSoundStrike,
    playSoundBoo,
    playCountUpWin,
    playGameOver,
    playJackpotSound,
    playSwooshSound,
    playTripleSevenLastStrike: () => {},
    playBallThrow,
    playBatImpact,
    playBallsFillingHopper,
    stopPlayBallsFillingHopper,
    playSportRunningSound,
    playCrowdCheer,
    stopCrowdCheer,
    playWinSound,
    playSoundWin,
    playOrgan1,
    stopOrgan1,
    playOrgan2,
    stopOrgan2,
    playOrgan3,
    stopOrgan3,
    playSportsCrowd,
    stopSportsCrowd,
    playAnthem,
    stopAnthem,
    playOut
  };
}

export default useSound